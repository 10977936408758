/*
 *
 */
import * as React from "react";
import styled from 'styled-components';
import logosrc from "./logo-2.svg";
import { Link } from 'gatsby';

// https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/pin-s+ac4629(4.13199,50.6675)/4.13199,50.6675,13.75,0,0/400x295@2x?access_token=pk.eyJ1IjoicXVlbnRpbi1kIiwiYSI6ImNrbnlxdXRuZTBsd3Eyd3J4aWtkdWswczEifQ.tmGlG8IovhmHSLU_SejFAg
// https://docs.mapbox.com/playground/static/

const LogoSvg = styled.div`
	position:relative;
	display:block;
	width:100%;
	/*max-width:190px;*/
	background:url(${logosrc}) left center no-repeat;
	background-size:contain;

	&:before {
		display:block;
		content:"";
		/*padding-top:33.6842105263%;*/
		padding-top:25.9109311741%;
	}

	margin-top:-7px;
	margin-bottom:-4.9px;
`;

const LogoText = styled.span`
	position:absolute;
	top:-9999px;
`;

/*
 *
 */
const Logo = () => {
	return (
		<LogoSvg as={Link} to="/">
			<LogoText>Isabelle Kopp</LogoText>
		</LogoSvg>
	);
}

export default Logo;
