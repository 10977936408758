/*
 *
 */
import * as React from 'react';
import { Link } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

import Header from '../header';
import Footer from '../footer';

/*
 *
 */
const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: 'Orpheus Pro';
		src: url('../../../fonts/orpheus-pro/orpheuspro-bold.eot');
		src: url('../../../fonts/orpheus-pro/orpheuspro-bold.eot?#iefix') format('embedded-opentype'), url('../../../fonts/orpheus-pro/orpheuspro-bold.woff2') format('woff2'), url('../../../fonts/orpheus-pro/orpheuspro-bold.woff') format('woff'), url('../../../fonts/orpheus-pro/orpheuspro-bold.ttf') format('truetype');
		font-weight: bold;
		font-style: normal;
		font-display:block;
	}

	@font-face {
		font-family: 'Orpheus Pro';
		src: url('../../../fonts/orpheus-pro/orpheuspro-medium.eot');
		src: url('../../../fonts/orpheus-pro/orpheuspro-medium.eot?#iefix') format('embedded-opentype'), url('../../../fonts/orpheus-pro/orpheuspro-medium.woff2') format('woff2'), url('../../../fonts/orpheus-pro/orpheuspro-medium.woff') format('woff'), url('../../../fonts/orpheus-pro/orpheuspro-medium.ttf') format('truetype');
		font-weight: 500;
		font-style: normal;
		font-display: block;
	}

	@font-face {
		font-family: 'Orpheus Pro';
		src: url('../../../fonts/orpheus-pro/orpheuspro.eot');
		src: url('../../../fonts/orpheus-pro/orpheuspro.eot?#iefix') format('embedded-opentype'), url('../../../fonts/orpheus-pro/orpheuspro.woff2') format('woff2'), url('../../../fonts/orpheus-pro/orpheuspro.woff') format('woff'), url('../../../fonts/orpheus-pro/orpheuspro.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
		font-display: block;
	}

	@font-face {
		font-family: 'Orpheus Pro';
		src: url('../../../fonts/orpheus-pro/orpheuspro-italic.eot');
		src: url('../../../fonts/orpheus-pro/orpheuspro-italic.eot?#iefix') format('embedded-opentype'), url('../../../fonts/orpheus-pro/orpheuspro-italic.woff2') format('woff2'), url('../../../fonts/orpheus-pro/orpheuspro-italic.woff') format('woff'), url('../../../fonts/orpheus-pro/orpheuspro-italic.ttf') format('truetype');
		font-weight: normal;
		font-style: italic;
		font-display: block;
	}

	html {
		font-size:10px;
		line-height: 1.15;
		-webkit-text-size-adjust: 100%;
	}

	body {
		margin:0;
		padding:0 8vw;
		box-sizing:border-box;
		overflow-y:scroll;

		@media (min-width:1024px) {
			padding:0 6vw;
		}
	}

	html, body {
		height:100%;
		color:#151a1e;
		background:#EEEDEB;
		font-family:'Orpheus Pro', serif;

		-moz-osx-font-smoothing:grayscale;
		-webkit-font-smoothing:antialiased;
		text-size-adjust:none;
	}

	#___gatsby,
	#gatsby-focus-wrapper {
		height:100%;
		display:flex;
		flex-direction:column;
		justify-content:space-between;
	}

	main {
		display: block;
	}

	a,
	a:active,
	a:visited {
		color:#AC4629;
		background-color: transparent;
	}

	b,
	strong {
		font-weight: bolder;
	}

	img {
		border-style: none;
	}

	* {
		-webkit-touch-callout:none; /* prevent callout to copy image, etc when tap to hold */
		-webkit-text-size-adjust:none; /* prevent webkit from resizing text to fit */
		-webkit-tap-highlight-color:rgba(0,0,0,0); /* prevent tap highlight color / shadow */
		-webkit-user-select:text; /* prevent copy paste, to allow, change 'none' to 'text' */
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		font-family: inherit;
		font-size: 100%;
		line-height: 1.15;
		margin: 0;
	}

	button,
	input {
		overflow: visible;
	}

	button,
	select {
		text-transform: none;
	}

	button,
	[type="button"],
	[type="reset"],
	[type="submit"] {
		-webkit-appearance: button;
	}

	button::-moz-focus-inner,
	[type="button"]::-moz-focus-inner,
	[type="reset"]::-moz-focus-inner,
	[type="submit"]::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}

	button:-moz-focusring,
	[type="button"]:-moz-focusring,
	[type="reset"]:-moz-focusring,
	[type="submit"]:-moz-focusring {
		outline: 1px dotted ButtonText;
	}
`;

const Main = styled.main`
	margin:0 auto;
	width:100%;
	max-width:1400px;
`;

/*
 *
 */
const Layout = ({ children }) => {
	return (
		<React.Fragment>
			<GlobalStyle />
			<Main>
				<Header />
				{children}
			</Main>
			<Footer />
		</React.Fragment>
	);
}

export default Layout;
