/*
 *
 */
import * as React from "react";
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';
import Logo from '../logo';

const BREAK_POINT = 1025;

/*
 *
*/
const Head = styled.nav`
	padding:6vw 0;
	display:flex;
	align-items:center;
	width:100%;
	height:45px;

	@media (min-width:${BREAK_POINT}px) {
		padding:3vw 0;
		height:auto;
	}
`;

const LogoArea = styled.div`
	width:50%;
	max-width:160px;
	min-width:1px;
	flex:0 0 auto;

	@media (min-width:768px) {
		max-width:200px;
	}

	@media (min-width:${BREAK_POINT}px) {
		max-width:240px;
	}
`;

const NavArea = styled.div`
	width:50%;
	min-width:1px;
	flex:1 1 auto;
`;

const Nav = styled.div`
	transition:none;

	@media (max-width:${BREAK_POINT - 1}px) {
		position:fixed;
		z-index:2;
		top:0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity:0;
		visibility:hidden;
		will-change:opacity,visibility;
		user-select:none;
		overflow-y:auto;
		height:100%;
		box-sizing:border-box;

		padding:6vw 0;
		background:#EEEDEB;

		font-size:8vmin;
		text-align:center;

		@media (min-width:576px) {
			font-size:6vmin;
		}

		@media (min-width:768px) {
			font-size:5vmin;
		}

		opacity:0;
		visibility:hidden;
		transition:visibility 400ms cubic-bezier(.4,0,.2,1), opacity 400ms cubic-bezier(.4,0,.2,1);

		${Head}.is-open & {
			opacity:1;
			visibility:visible;
		}
	}


`;

const NavItems = styled.ul`
	margin:0;
	padding:0;
	list-style:none;

	padding:6vw;
	height:100%;
	box-sizing:border-box;

	display:flex;
	flex-direction:column;
	justify-content:center;

	@media (min-width:${BREAK_POINT}px) {
		display:flex;
		flex-direction:row;
		justify-content:flex-end;
		margin-left:auto;
		font-size:1.7rem;
		font-weight:500;
		padding:0;

		text-align:left;
	}
`;

const NavItem = styled.li`
	@media (max-width:${BREAK_POINT - 1}px) {
		padding:1vh 0;
		will-change:transform;
		transform:translateY(20px);
		transition:transform 600ms cubic-bezier(.4,0,.2,1);

		${Head}.is-open & {
			transform:translateY(0);
		}
	}

	@media (min-width:${BREAK_POINT}px) {
		margin-right:1.5vw;

		padding:0;
		&:last-child {
			margin-right:0;
		}

		transform:none;
		transition:none;
	}
`;

const NavLink = styled.a`
	&,
	&:visited,
	&:active {
		color:#151a1e;
	}

	display:inline-block;
	padding:1.5vw 0;
	text-decoration:none;

	@media (min-width:${BREAK_POINT}px) {
		display:block;
		padding:.25em 0;
		border-top:1px solid transparent;
		border-bottom:1px solid transparent;

		&.is-active {
			border-bottom:1px solid #151a1e;
		}
	}
`;

const Toggle = styled.button`
	position:absolute;
	top:6vw;
		right:6vw;

	${Head}.is-open & {
		position:fixed;
	}

	z-index:2;
	margin-left:auto;

	display:block;
	width:35px;
	height:35px;
	padding:5px;
	box-sizing:content-box;

	cursor:pointer;
	background-color:transparent;
	border:none;
	outline:0;

	@media (min-width:${BREAK_POINT}px) {
		display:none;
	}
`;

const Burger = styled.div`
	position:relative;
	margin:0 auto;
	display:block;
	width:31px;
	height:31px;
`;

const BurgerTop = styled.div`
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	content: '';
	width: 100%;
	height: 1px;
	transition: transform 250ms cubic-bezier(.2,.6,.3,1),width 250ms cubic-bezier(.2,.6,.3,1);
	will-change: transform,width;
	background:#000;
	transform: translatey(-5.5px);

	${Head}.is-open & {
		transform:translatex(3.5px) rotate(-135deg);
		width:26px;
	}
`;

const BurgerBottom = styled.div`
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	content: '';
	width: 100%;
	height: 1px;
	transition: transform 250ms cubic-bezier(.2,.6,.3,1),width 250ms cubic-bezier(.2,.6,.3,1);
	will-change: transform,width;
	background:#000;
	transform: translateY(5.5px);

	${Head}.is-open & {
		transform:translatex(3.5px) rotate(135deg);
		width:26px;
	}
`;

const BodyOverflow = createGlobalStyle`
	@media (max-width:${BREAK_POINT - 1}px) {
		body {
			overflow-y:hidden !important;
		}
	}
`;

/*
 *
 */
const Header = () => {

	const [opened, setOpened] = React.useState(false);

	return (
		<Head className={opened ? 'is-open' : null}>
			<LogoArea>
				<Logo />
			</LogoArea>
			<NavArea>
				<Nav>
					<NavItems>
						<NavItem>
							<NavLink onClick={() => setOpened(false)} activeClassName="is-active" as={Link} to="/">Accueil</NavLink>
						</NavItem>
						<NavItem>
							<NavLink onClick={() => setOpened(false)} activeClassName="is-active" as={Link} to="/consultation-adulte/">Adultes</NavLink>
						</NavItem>
						<NavItem>
							<NavLink onClick={() => setOpened(false)} activeClassName="is-active" as={Link} to="/consultation-adolescent/">Adolescents</NavLink>
						</NavItem>
						<NavItem>
							<NavLink onClick={() => setOpened(false)} activeClassName="is-active" as={Link} to="/consultation-maternologie/">Maternologie</NavLink>
						</NavItem>
						<NavItem>
							<NavLink onClick={() => setOpened(false)} activeClassName="is-active" as={Link} to="/rendez-vous/">Rendez-vous</NavLink>
						</NavItem>
					</NavItems>
				</Nav>
				<Toggle onClick={() => setOpened(opened ? false : true)}>
					<Burger>
						<BurgerTop />
						<BurgerBottom />
					</Burger>
				</Toggle>
				{opened ? <BodyOverflow /> : null}
			</NavArea>
		</Head>
	);
}

export default Header;
