/*
 *
 */
import * as React from "react";
import styled from 'styled-components';
import { Link } from 'gatsby';

/*
 *
*/
const Foot = styled.nav`
	margin:0 auto;
	width:100%;
	max-width:1400px;
	padding:10vw 0;

	line-height:1;
	font-size:1.2rem;
	text-align:center;

	& ul {
		margin:0;
		padding:0;
		list-style:none;

		display:flex;
		justify-content:center;
	}

	& li {
		margin:0;
		position:relative;
		padding:0 1.5rem;

		&::after {
			position:absolute;
			top:50%;
			right:0;
			margin-top:-.7rem;

			height:1.4rem;
			border-right:1px solid;
			content:"";
			opacity:.5;
		}

		&:last-child::after {
			display:none;
		}


		&:first-child {
			text-align:right;
		}

		&:last-child {
			text-align:left;
		}
	}

	& a {
		vertical-align:top;
		text-decoration:none;
		border-bottom:1px solid;
	}

	@media (min-width:768px) {
		& li {
			padding:0 2vw;
		}
	}

	@media (min-width:1024px) {
		font-size:1.5rem;
		padding:6vw 0;

		& li {
			width:50%;

		}
	}
`;

/*
 *
 */
const Footer = () => {
	return (
		<Foot>
			<ul>
				<li>© Isabelle Kopp 2021</li>
				<li>
					<Link to="/mentions-legales/">Mentions légales</Link>
				</li>
			</ul>
		</Foot>
	);
}

export default Footer;
