// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consultation-adolescent-js": () => import("./../../../src/pages/consultation-adolescent.js" /* webpackChunkName: "component---src-pages-consultation-adolescent-js" */),
  "component---src-pages-consultation-adulte-js": () => import("./../../../src/pages/consultation-adulte.js" /* webpackChunkName: "component---src-pages-consultation-adulte-js" */),
  "component---src-pages-consultation-maternologie-js": () => import("./../../../src/pages/consultation-maternologie.js" /* webpackChunkName: "component---src-pages-consultation-maternologie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-rendez-vous-js": () => import("./../../../src/pages/rendez-vous.js" /* webpackChunkName: "component---src-pages-rendez-vous-js" */)
}

